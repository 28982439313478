import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
	getFirestore,
	doc,
	getDocs,
	collection,
	Timestamp,
} from 'firebase/firestore';

import { Item } from '../../components/Game/Scoreboard';
import Logo from '../../components/Logo';
import Feudle from '../../components/Feudle/index';

type FeudleType = {
	date: Timestamp;
	query: string;
	answer: string;
};

const FeudleArchive = () => {
	const [feudles, setFeudles] = React.useState<FeudleType[]>([]);
	const [feudle, setFeudle] = React.useState<FeudleType>();

	React.useEffect(() => {
		const asyncLoadFeudleArchive = async () => {
			const archiveRef = await getDocs(
				collection(getFirestore(), 'Feudle/Daily/Archive')
			);
			setFeudles(archiveRef.docs.map((doc) => doc.data() as FeudleType));
		};
		asyncLoadFeudleArchive();
	}, [getDocs, setFeudles, doc, getFirestore]);

	return (
		<>
			<Helmet>
				<title>Google Feud - Feudle - Archive</title>
			</Helmet>
			<Logo isFeudle />
			{feudle && (
				<div style={{ marginBottom: '10rem' }}>
					<Feudle load={feudle} key={feudle.query} />
				</div>
			)}
			<div>
				<Item
					scoreName={`Feudle Archive`}
					scoreValue={
						<ul>
							{feudles
								.sort((f1, f2) => f2.date.seconds - f1.date.seconds)
								.map((f, index) => {
									if (!index) {
										return <></>;
									}
									return (
										<li
											key={f.date.seconds}
											className="altList"
											style={{ cursor: 'pointer', padding: '1rem' }}
											onClick={() => setFeudle(f)}>
											{`${f.date.toDate().toLocaleDateString()} - ${f.query}`}
										</li>
									);
								})}
						</ul>
					}
				/>
			</div>
		</>
	);
};
export default FeudleArchive;
